<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>Precios y descuentos</span>                         
        </div>

        <div class="pl-3 pt-3 pr-3" style="height: 80vh;" v-if="item_product">
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-title class="pt-2 pb-2">
                                <span class="subtitle-2 font-weight-bold">{{ item_product.description }}</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-text-field v-model="quantity"
                                    label="Cantidad"
                                    outlined
                                    hide-details="auto"
                                ></v-text-field>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row v-if="user_roles == 'superadmin'">
                    <v-col cols="12">
                        <v-card>
                            <v-card-title class="pt-1 pb-2">
                                <v-switch 
                                    v-model="is_bonus" 
                                    class="mt-0" 
                                    label="BONIFICACIÓN"
                                    hide-details
                                    :false-value="false"
                                    :true-value="true"
                                ></v-switch>
                            </v-card-title>                            
                        </v-card>
                    </v-col>
                </v-row>

                <v-row v-if="user_roles == 'superadmin'">
                    <v-col cols="12">
                        <v-card>
                            <v-card-title class="pt-1 pb-1">
                                <span class="subtitle-2 font-weight-bold">Lista de precios</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text class="pt-1 pb-1">
                                <v-radio-group v-model="price_selected" dense>                                    
                                    <v-radio
                                        v-for="price in prices"
                                        :key="price.id"
                                        :value="price.price"
                                        :label="price.price | currency('S/')"
                                    >
                                    </v-radio>
                                </v-radio-group>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-if="user_roles == 'superadmin'">
                    <v-col cols="12">
                        <v-card>
                            <v-card-title class="pt-1 pb-1">
                                <span class="subtitle-2 font-weight-bold">Descuento</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text class="pt-1 pb-1">
                                <v-container>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-btn-toggle
                                                v-model="discount_type"
                                                mandatory
                                                color="green darken-3"
                                                class="mr-3">
                                                <v-btn value="Monto">
                                                    S/ 
                                                </v-btn>
                                                <v-btn value="Porcentaje">
                                                    %
                                                </v-btn>
                                            </v-btn-toggle>
                                        </v-col>
                                        <v-col cols="8">
                                            <v-text-field :label="`${discount_type} a descontar`" 
                                                class="field-discount" 
                                                v-model="discount_amount"
                                                outlined
                                                hide-details="auto"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleCancel">
                            <v-icon class="mr-1" size="16">far fa-times-circle</v-icon>
                            Cancelar
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn dark 
                            color="success" 
                            block 
                            height="40" 
                            :disabled="process_operation"
                            @click="handleStore">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Guardar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </div>

    </v-navigation-drawer>

</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
    name: 'Prices',
    data () {
        return {
            drawer: false,
            item_product: null,
            quantity: null,
            price_selected: null,            
            discount_type: null,
            discount_amount: null,
            is_bonus: null,
            process_operation: false
        }
    },
    computed: {
        ...mapState('authentication', [
            'user_roles'
        ]),
        ...mapState('products', [
            'prices'
        ])
    },
    methods: {
        ...mapActions('products', [
            'getPrices',
            'empty_prices'
        ]),
        ...mapActions('orders', [
            'updateDetail'
        ]),
        show (product) {
            this.clearData();
            this.item_product = product;
            if (this.item_product) {
                this.loadData(this.item_product);
            }
            this.drawer = !this.drawer;
        },
        async loadData (product) {
            this.quantity = product.quantity;
            this.price_selected = product.price;
            this.is_bonus = product.is_bonus;
            if (this.item_product.discount_type) {
                this.discount_type = this.item_product.discount_type;
            }
            if (this.item_product.discount_amount) {
                this.discount_amount = this.item_product.discount_amount;
            }
            await this.getPrices(product);
        },
        clearData () {
            this.empty_prices();
            this.quantity = null;
            this.item_product = null;
            this.price_selected = null;
            this.discount_type = null;
            this.discount_amount = null;
        },
        closeForm() {
            this.empty_prices();
            this.drawer = false;
        },
        handleCancel () {
            this.closeForm();
        },
        async handleStore () {
            this.process_operation = true;
            this.item_product.is_bonus = this.is_bonus;
            this.item_product.quantity = this.quantity;
            this.item_product.discount_type = this.discount_type;
            this.item_product.discount_amount = this.discount_amount;
            if (this.price_selected) {
                this.item_product.price = this.price_selected;
            }
            this.item_product.subtotal = parseFloat(this.item_product.quantity) * parseFloat(this.item_product.price);
            this.calculateDiscountAmount();

            if (this.item_product.discount_value >= this.item_product.subtotal) {
                this.process_operation = false;
                this.$swal({
                    text: "El descuento debe ser menor al total del item",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Aceptar"
                });
            } else {
                let newItem = Object.assign({}, this.item_product);
                await this.updateDetail(newItem);
                this.process_operation = false;
                this.closeForm();
            }
        },
        calculateDiscountAmount () {
            if (this.item_product.discount_type) {
                let amount_discount = parseFloat(this.item_product.discount_amount);
                if (this.item_product.discount_type == 'Porcentaje') {
                    let percentage = parseFloat(this.item_product.discount_amount);
                    let discount = parseFloat(this.item_product.subtotal) * (percentage / 100);
                    amount_discount = Math.round(discount * 100) / 100;
                }
                this.item_product.discount_value = Math.round(amount_discount * 100) / 100;
            }
        }
    }
}
</script>

<style scoped>

</style>