<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>Catálogo de productos</span>
            <v-icon color="error"
                class="float-right"
                @click="handleCancel">
                fas fa-window-close
            </v-icon>
        </div>

        <div class="pl-3 pt-3 pr-3" style="height: 80vh;">
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            ref="category_id"
                            v-model="form.category_id"
                            :items="categories"
                            label="Categoría"
                            outlined
                            :item-text="customText"
                            item-value="id"
                            hide-details="auto"
                            clearable
                            @change="handleChangeCategory"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="11">
                        <v-text-field v-model="form.description"
                            label="Producto"
                            outlined
                            hide-details="auto"
                            @keydown="handleInputSearchByEnter"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="1" class="pl-0">
                        <v-icon size="22" class="mt-2"
                            @click="handleSearchByDescription">
                            fas fa-search
                        </v-icon>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-card class="section-catalogue">
                            <v-card-text class="pl-0 pr-0 pt-0 pb-0">
                                <template>
                                    <v-list Three-line dense class="pt-0">
                                    <v-list-item v-for="product in catalogue" :key="product.id" 
                                        class="catalogue-item">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <span class="font-weight-bold">{{ product.description }}</span>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <span class="font-weight-bold" v-if="product.brand_name">MARCA: {{ product.brand_name }}</span>
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle>
                                                <span class="font-weight-bold" v-if="product.stock">STOCK: {{ product.stock }}</span>
                                                <span class="font-weight-bold" v-else>STOCK: 0.00</span>
                                                <span class="ml-2">/</span>
                                                <span class="ml-2 font-weight-bold">PRECIO: {{ product.price | currency('S/') }}</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-icon v-if="product.stock && product.stock > 0"
                                                color="blue" 
                                                @click="handleSelectProduct(product)">
                                                fas fa-cart-arrow-down
                                            </v-icon>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                                </template>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-btn block height="40" dark color="red" @click="handleCancel">
                            <v-icon class="mr-1" size="16">far fa-times-circle</v-icon>
                            Cerrar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </div>

        <add-quantity ref="addQuantity"></add-quantity>

    </v-navigation-drawer>
</template>

<script>
import {mapActions, mapState} from 'vuex';

import AddQuantity from './AddQuantity';

export default {
    name: 'Catalogue',
    components: {
        AddQuantity
    },
    data () {
        return {
            drawer: false,
            action: null,
            order_id: null,
            form: {
                category_id: null,
                description: null
            }
        }
    },
    computed: {
        ...mapState('categories', [
            'categories'
        ]),
        ...mapState('inventories', [
            'catalogue'
        ]),
    },
    methods: {
        ...mapActions('inventories', [
            'run_set_catalogue',
            'getCatalogue'
        ]),
        show (action, order_id = null) {
            this.clearForm();
            this.action = action;
            if (order_id) {
                this.order_id = order_id;
            }
            this.drawer = !this.drawer;
        },
        clearForm () {
            this.action = null;
            this.order_id = null;
            this.form.category_id = this.categories[0].id;
            this.form.description = null;
            this.getCatalogue(this.form);
        },
        customText(item){
            let text = item.name
            
            return text
        },
        handleCancel () {
            this.drawer = false;
        },
        searchProduct () {
            this.getCatalogue(this.form);
        },
        handleChangeCategory (value) {
            this.form.category_id = null;
            if (value !== undefined && value !== null) {
                this.form.category_id = value;
            }
            this.searchProduct();
        },
        handleInputSearchByEnter(KeyboardEvent){
            if (KeyboardEvent.code == 'Enter') {
                this.handleSearchByDescription();
            }
        },
        handleSearchByDescription () {
            this.searchProduct();
        },
        handleSelectProduct (product) {
            let productOrder = this.setProductOrder(product);
            this.$refs.addQuantity.show(this.action, productOrder, this.order_id);
        },
        setProductOrder (product) {
            let today = new Date();
            let productOrder = {
                id: today.getTime(),
                product_id: product.product_id,
                description: product.description,
                category_id: product.category_id,
                category_name: product.category_name,
                brand_name: product.brand_name,
                sku: product.sku,
                price: parseFloat(product.price),
                quantity: null,
                subtotal: null,
                discount: null,
                is_bonus: false
            }

            return productOrder;
        }
    }
}
</script>

<style scoped>
.section-catalogue {
    height: 55vh;
    overflow-y: auto;
}

.label-stock {
    color: #3F51B5;
}

.label-price {
    color: #388E3C;
}
</style>