<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="1" class="d-md-none pb-1 pt-0" >
                    <v-icon size="16" @click="handleViewMenu" class="ml-2 mr-2">
                        fas fa-bars
                    </v-icon>
                </v-col>
                <v-col :md="6" :sm="6" cols="5" class="pb-1 pt-0">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            :to="item.href"
                            :disabled="item.disabled"
                            >
                            {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <office-login></office-login>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="10" class="pt-1">
                    <span class="headline font-weight-medium">Detalle de Pedido</span>
                </v-col>
                <v-col cols="2" class="pt-1 d-none d-sm-block" 
                    v-if="this.$hasPermision('pedidos.despachar')">
                    <v-btn v-if="sale == null"
                        dark 
                        color="success" 
                        width="140" 
                        class="float-right"
                        @click="handleDeliverOrder">
                        Despachar
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="1" class="pt-1 pb-1 d-none d-sm-block">
                    <span>Fecha</span>
                </v-col>
                <v-col :md="8" :sm="8" cols="12" class="pt-1 pb-1">
                    <span class="label-purchase">{{ order_selected.order_date | moment("DD/MM/YYYY") }}</span>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="1" class="pt-1 pb-1 d-none d-sm-block">
                    <span>Cliente</span>
                </v-col>
                <v-col :md="8" :sm="8" cols="12" class="pt-1 pb-1">
                    <span class="label-purchase">{{ order_selected.customer_name }}</span>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="1" class="pt-1 pb-1 d-none d-sm-block">
                    <span>Dirección</span>
                </v-col>
                <v-col :md="8" :sm="8" cols="12" class="pt-1 pb-1">
                    <span class="label-purchase">{{ order_selected.address }}</span>
                </v-col>
                <v-col :md="3" :sm="3" cols="12" class="pt-1 pb-1">
                    <v-btn color="blue" 
                        dark 
                        class="float-right"
                        @click="handleViewCatalogue">
                        Agregar
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-data-table
                            :headers="headers"
                            :items="order_selected.detail"
                            :items-per-page="10"
                            class="elevation-1 w-table"
                            height="55vh"
                            fixed-header
                        >

                            <template v-slot:item.price="{item}">
                                <span>{{ item.price | currency('S/') }}</span>
                            </template>

                            <template v-slot:item.discount_value="{item}">
                                <span>{{ item.discount_value | currency('S/') }}</span>
                            </template>
                            <template v-slot:item.subtotal="{item}">
                                <v-icon v-if="item.is_bonus"
                                    size="18" 
                                    class="mr-2"
                                    color="green">
                                    fas fa-gift
                                </v-icon>
                                <span>{{ getAmountProductDiscount(item) | currency('S/') }}</span>                                
                            </template>

                            <template v-slot:item.actions={item}>
                                <v-icon 
                                    color="amber" 
                                    size="18"
                                    @click="handleEditItem(item)">
                                    fas fa-pencil-alt
                                </v-icon>
                                <v-icon 
                                    size="18" 
                                    color="error"
                                    class="ml-3"
                                    @click="handleDeleteIem(item)"
                                    >
                                    far fa-times-circle
                                </v-icon>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>

        </template>

        <Catalogue ref="catalogue"></Catalogue>
        <Payment ref="payment"></Payment>
        <Prices ref="prices"></Prices>
    </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import OfficeLogin from '../../../components/auth/OfficeLogin';
import Payment from '../../../components/orders/Payment';
import Prices from '../../../components/orders/Prices';
import Catalogue from '../../../components/orders/Catalogue';

export default {
    name: 'DetailOrder',
    components: {
        Catalogue,
        OfficeLogin,
        Payment,
        Prices
    },
    data () {
        return {
            itemsBreadcrumbs: [
                {
                    text: 'Ventas',
                    disabled: true,
                    href: '',
                },
                {
                    text: 'Pedidos',
                    disabled: false,
                    href: '/app/ventas/pedidos',
                }
            ],
            headers: [
                { text: 'DESCRIPCIÓN', value: 'description', sortable: false },
                { text: 'MARCA', value: 'brand_name', sortable: false },
                { text: 'SKU', value: 'sku', sortable: false },
                { text: 'CANTIDAD', value: 'quantity', sortable: false, align: 'end' },
                { text: 'P. UNITARIO', value: 'price', sortable: false, align: 'end' },
                { text: 'DESCUENTO', value: 'discount_value', sortable: false, align: 'end' },
                { text: 'TOTAL', value: 'subtotal', sortable: false, align: 'end' },
                { text: '', value: 'actions', sortable: false, align: 'end' },
            ]
        }
    },
    computed: {
        ...mapState('orders', [
            'order_selected',
            'sale'
        ])
    },
    methods: {
        ...mapActions('authentication', [
            'setShowMenu'
        ]),
        ...mapActions('orders', [
            'deleteDetail'
        ]),
        ...mapActions('categories', [
            'getCategories'
        ]),
        handleViewMenu () {
            this.setShowMenu(true);
        },
        handleDeliverOrder () {
            this.$refs.payment.showForm();
        },
        handleViewCatalogue () {
            this.$refs.catalogue.show('update', this.order_selected.id);
        },
        handleEditItem (product) {
            let itemProduct = Object.assign({}, product);
            this.$refs.prices.show(itemProduct);
        },
        getAmountProductDiscount(item) {
            let amount = (item.price * item.quantity) - item.discount_value;
            amount = Math.round( (amount) * 100) / 100;

            return amount;
        },
        handleDeleteIem (item) {
            this.$swal({
                title: "¿Desea confirmar?",
                text: "Confirma que desea eliminar el item",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if(result.value){
                    if(result.value === true){
                        this.deleteDetail(item);
                    }
                }
            });
        }
    },
    created () {
        this.getCategories();
    }
}
</script>

<style scoped>

</style>