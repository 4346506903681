<template>
    <v-dialog
        v-model="dialog"
        width="350"
        transition="fab-transition"
    >
        <v-card class="pl-0 pr-0">
            <v-card-title>
                <v-icon class="mr-3">fas fa-box-open</v-icon>
                <span class="description-selected">
                    {{ description }}
                </span> 
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pb-0">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="form.quantity"
                                ref="quantity"
                                label="Cantidad"
                                type="number"
                                autofocus
                                outlined
                                @keydown="handleInputSearchByEnter"
                                :rules="[
                                    () => !!form.quantity || 'Debe ingresar la cantidad',
                                    () => (!!form.quantity && parseInt(form.quantity) > 0) || 'Cantidad debe ser mayor a cero',
                                ]"
                                hide-details="auto"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-container class="pt-0 pb-0">
                    <v-row>
                        <v-col cols="6">
                            <v-btn dark color="red" block @click="handleCancel">
                                Cancelar
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn dark color="green" block @click="handleOk">
                                Aceptar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-card>

    </v-dialog>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import ArrayTools from '../../helpers/ArrayTools';

export default {
    name: 'AddQuantity',
    data () {
        return {
            order_id: null,
            action: null,
            dialog: false,
            description: null,
            product: null,
            form: {
                quantity: null
            }
        }
    },
    computed: {
        ...mapState('orders', [
            'detail'
        ])
    },
    methods: {
        ...mapActions('orders', [
            'addItemToOrder',
            'replaceItemInOrder',
            'addDetail'
        ]),
        show (action, productOrder, order_id = null) {
            this.clearData();
            this.action = action;
            this.description = productOrder.description;            
            this.product = Object.assign({}, productOrder);
            this.form.quantity = this.product.quantity;
            if (order_id) {
                this.order_id = order_id;
            }
            this.dialog = !this.dialog;
        },
        async clearData () {
            this.action = null;
            this.order_id = null;
            this.description = null;
            this.product = null;
            this.form.quantity = null;
            await this.clearErrors();
        },
        handleCancel () {
            this.dialog =  false;
        },
        closeForm () {
            this.clearData();
            this.dialog = false;
        },
        handleInputSearchByEnter(KeyboardEvent){
            if (KeyboardEvent.code == 'Enter') {
                this.handleOk();
            }
        },
        handleOk () {
            if (!this.existErrors()) {
                if (this.action == 'create') {
                    this.addProductToCreateOrder();
                } else if (this.action == 'update') {
                    this.addProductToUpdateOrder();
                }
            }
        },
        addProductToCreateOrder () {
            let index = ArrayTools.getIndexByProductIdInOrder(this.detail, this.product.product_id);
            let item = Object.assign({}, this.product);
            item.quantity = this.form.quantity;
            item.subtotal = parseFloat(item.price) * parseFloat(item.quantity);
            
            if (index == -1) {
                this.addItemToOrder(item);
            } else {
                let data = {
                    index: index,
                    item: item
                }
                this.replaceItemInOrder(data);
            }
            this.closeForm();
        },
        async addProductToUpdateOrder () {
            let index = ArrayTools.getIndexByProductIdInOrder(this.detail, this.product.product_id);
            if (index > -1) {
                this.$swal({
                    text: "Producto se encuentra agregado, seleccione otro.",
                    icon: "warning",
                    showCancelButton: false,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Aceptar"
                });
                this.closeForm();
            } else {
                let item = Object.assign({}, this.product);
                item['order_id'] = this.order_id;
                item.quantity = this.form.quantity;
                item.subtotal = parseFloat(item.price) * parseFloat(item.quantity);
                await this.addDetail(item);
                console.log(item);
                this.closeForm();                
            }            
        },
        existErrors(){
            let hasErrors = false;

            Object.keys(this.form).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.form[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        },
        clearErrors() {
            Object.keys(this.form).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        },
    }
}
</script>

<style scoped>

</style>